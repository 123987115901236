<template>
  <main>
    <div class="row screen_9">
      <div class="col-12 col-lg-12">
        <div class="warp_block_1 d-flex flex-column">
          <div class="title_block_1">Cпасибо за покупку, билет выслан вам на электронный адрес</div>
          <div class="d-flex justify-content-center">
            <a class="bt" href="https://karelforum.ru/">назад</a>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>

export default {
  name: 'Finish',
  components: {

  },
  data() {
    return {}

  },
}

</script>
